import './App.css';
import { Box, width } from '@mui/system';
import { Avatar, Button, Typography, Grid } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import back from './Assets/Cards/card(0).png';
import allCardImages from './cards'; // Import the dynamically loaded images
import Board from "./Assets/Board.png";
import Win from "./Assets/Win.mp4"; // Import the win video
import Shaffling from "./Assets/v1.gif"; // Import the shuffling video
import CoutDown from "./Assets/CountDown.mp4"; // Import the countdown video
import Loss from "./Assets/loss.mp4";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const columns = [
  { id: 'name', label: 'Username', minWidth: 100 },
  { id: 'code', label: 'Amount', align: 'center',minWidth: 60 },
  {
    id: 'population',
    label: 'Profit',
    minWidth: 50,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('John Doe', 2000, 5000),
  createData('Jane Smith', 1500, 4000),
  createData('Michael Johnson', 1800, 4500),
  createData('Emily Davis', 1200, 3500),
  createData('Chris Brown', 1000, 3000),
  createData('Jessica Taylor', 900, 2500),
  createData('Daniel White', 1100, 2800),
  createData('Sophia Lee', 1300, 3700),
  createData('James Wilson', 1600, 4200),
  createData('Olivia Martin', 1400, 3900),
  createData('Liam Garcia', 1700, 4300),
  createData('Noah Anderson', 1000, 3200),
  createData('Isabella Moore', 1900, 4700),
  createData('Elijah Harris', 2100, 5100),
  createData('Liam Garcia', 1700, 4300),
  createData('Noah Anderson', 1000, 3200),
  createData('Isabella Moore', 1900, 4700),
  createData('Elijah Harris', 2100, 5100),  
];

const rows1 = [
  // createData('John Doe', 2000, 5000),
  // createData('Jane Smith', 1500, 4000),
  // createData('Michael Johnson', 1800, 4500),
  // createData('Daniel White', 1100, 2800),
  // createData('Sophia Lee', 1300, 3700),
  // createData('James Wilson', 1600, 4200),
  // createData('Olivia Martin', 1400, 3900),
  // createData('Liam Garcia', 1700, 4300),
  // createData('Noah Anderson', 1000, 3200),
  // createData('Isabella Moore', 1900, 4700),
  // createData('Elijah Harris', 2100, 5100),
 
];

function App() {
  const [shuffledImages, setShuffledImages] = useState([]);
  const [shuffledImages1, setShuffledImages1] = useState([]);
  const [shuffledImages2, setShuffledImages2] = useState([]);
  const [cardZIndex, setCardZIndex] = useState({});
  const [randomGameCard, setRandomGameCard] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control the dialog visibility
  const [popupMessage, setPopupMessage] = useState(""); // State to control the popup message
  const [showWinVideo, setShowWinVideo] = useState(false); // State to control video display
  const [showShufflingVideo, setShowShufflingVideo] = useState(true); // State to control shuffling video
  const [showCountdownVideo, setShowCountdownVideo] = useState(false); // State to control countdown video
  const [cardsReadyToMove, setCardsReadyToMove] = useState(false); // State to trigger card movement
  const [cardMoves, setCardMoves] = useState({});
  const [cardFlippedAfterMove, setCardFlippedAfterMove] = useState({});
  const [gameCardIndex, setGameCardIndex] = useState(null);
  const [youAmount, setYouAmount] = useState(200);
  const [opponentAmount, setOpponentAmount] = useState(100);
  const [player1Array, setPlayer1Array] = useState([]);
  const [player2Array, setPlayer2Array] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);
  const [bettingTime, setBettingTime] = useState(false)
  

  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  };

  useEffect(() => {
    // Select a random game card from the first 13 cards
    const selectedGameCard = allCardImages[Math.floor(Math.random() * 13)];
    setRandomGameCard(selectedGameCard);
  
    // Get the index of the selected game card in the original array
    const index = allCardImages.indexOf(selectedGameCard);
    setGameCardIndex(index);
  
    
  }, []);
  
  const sideRef = useRef(null);

  // Using useEffect to log or perform actions when the state is updated
useEffect(() => {
  
}, [youAmount, opponentAmount, bettingTime]);

  useEffect(() => {
    if (cardsReadyToMove && !showWinVideo) {
      shuffledImages.forEach((_, index) => {
        setTimeout(() => {
          if (showWinVideo) return;
  
          setCardZIndex((prev) => ({
            ...prev,
            [index]: Math.max(...Object.values(prev), 0) + 1,
          }));
  
          setCardMoves((prev) => ({ ...prev, [index]: true }));
  
          setTimeout(() => {
            if (index === 0 || index === 1) {
              setCardFlippedAfterMove((prev) => ({ ...prev, [index]: false }));
            } else {
              setCardFlippedAfterMove((prev) => ({ ...prev, [index]: true }));
            }
          }, 500);
  
          setTimeout(() => {
            if (
              index > 1 &&
              (
                shuffledImages[index] === allCardImages[gameCardIndex] ||
                shuffledImages[index] === allCardImages[gameCardIndex + 39] ||
                shuffledImages[index] === allCardImages[gameCardIndex + 13] ||
                shuffledImages[index] === allCardImages[gameCardIndex + 26]
              )
            ) {
              const newSide = index % 2 === 1 ? "Player 02" : "Player 01";
              sideRef.current = newSide; // Update the ref
              console.log(sideRef.current);
              
              setIsPopupOpen(true);
              setShowWinVideo(true);
            }
          }, 1000);
        }, 1000 * index);
      });
    }
  }, [cardsReadyToMove, shuffledImages, randomGameCard, showWinVideo]);
  
      
  

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup dialog
  };

  const cards = Array.from({ length: 53 }, (_, index) => (
    <Avatar
      key={index}
      src={cardFlippedAfterMove[index] ? shuffledImages[index] : back}
      alt={`card-${index + 1}`}
      sx={{
        width: "60%",
        height: "auto",
        borderRadius: "0px",
        marginTop: "10px",
        position: "absolute",
        top: "25%",
        left: "25%",
        cursor: "pointer",
        transition: "transform 0.5s ease, z-index 0.5s ease",
        transform: cardMoves[index]
          ? index === 1
            ? 'translate(10%,150%)'
            : index === 0
            ? 'translateY(150%)'
            : index % 2 === 0
            ? 'translate(273%, 250%)'
            : 'translate(273%, 70%)'
          : 'none',
        zIndex: cardZIndex[index] || 0,
      }}
    />
  ));

  const [activePlayer, setActivePlayer] = useState(null);

  const handleToggle = (player) => {
    setActivePlayer(player);
  };

  const [selectedValue, setSelectedValue] = useState(null); // Manage the selected value
  const [betAmount, setBetAmount] = useState("");

  const handleToggle1 = (value) => {
    setBetAmount(value);
    setSelectedValue(value); // Update the selected value on click
  };


  const [betData, setBetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableBackground, setTableBackground] = useState("");
  const [textcolor, setTextcolor] = useState("");
  const [error, setError] = useState(null);
  const [isBetPlaced, setIsBetPlaced] = useState(false);
  const [data, setData] = useState({
    userCount: 0,
    totalBetAmount: 0,
  });

  const fetchPlayerBets = async () => {
    try {
        const response = await axios.get('http://127.0.0.1:8000/api/player-total-bet'); // Replace with your actual backend URL

        const newYouAmount = response.data.Player_One;
        const newOpponentAmount = response.data.Player_Two;

        // Set state
        setYouAmount(newYouAmount);
        setOpponentAmount(newOpponentAmount);

        

    } catch (error) {
        console.error('Error fetching player bets:', error);
    }
};


  // Fetch initial bet data from the backend on component mount
  useEffect(() => {
    axios
      .get('https://cardgamebackend.gamingesupport.xyz/api/bet-data') // Replace with your actual backend URL
      .then((response) => {
        setBetData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the bet data!', error);
        setError(error);
        setLoading(false);
      });

      const fetchData = async () => {
        try {
          const response = await axios.get('https://cardgamebackend.gamingesupport.xyz/api/user-count');
          const { 'User Count': userCount, 'Total Bet Amount': totalBetAmount } = response.data;
    
          setData({
            userCount,
            totalBetAmount,
          });
        } catch (err) {
          setError('Failed to fetch data');
        }
      };
    
      // Polling every 5 seconds (5000 milliseconds)
      const interval = setInterval(fetchData, 5000);
    
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);

  }, []);


  

  // Handle placing a bet
  const handlePlaceBet = () => {
    // Prepare the data to send to the backend
    const newBet = {
      winning_player: activePlayer,
      bet_amount: betAmount,
      wining_profit : betAmount*2,
      id: betData.length + 1,
    };

    // Axios POST request to send the data to the backend
    axios
      .post('https://cardgamebackend.gamingesupport.xyz/api/place-bet', newBet) // Update the URL as per your backend API route
      .then((response) => {
        // Handle success response
        //alert(response.data.message);

        // Update the state with the new bet data optimistically
        setBetData((prevData) => [...prevData, newBet]); // Add the new bet to the current list
        setBetAmount(''); // Reset bet amount
        // setActivePlayer(''); // Reset active player
        setIsBetPlaced(true);
        
       // alert(activePlayer);
      })
      .catch((error) => {
        // Handle error response
        console.error('There was an error placing the bet!', error);
      });
  };

  if (loading) {
    return <Box sx={{ width:"100%", height:"100vh", backgroundColor:"#185519"}}></Box>;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }


  
   

  const updateWinner = async (winner) => {
    try {
        const response = await axios.get('https://cardgamebackend.gamingesupport.xyz/api/winner', {
            params: {
                winning_player: winner,
            },
        });
        console.log(response.data.message);
    } catch (error) {
        console.error('Error updating winner:', error.response.data);
    }
};



  function colorChange() {
    
    if(showWinVideo && (activePlayer !== sideRef.current)) {
      setTableBackground("rgba(0, 255, 0, 0.2)");
      setTextcolor("green");
    } else {
      setTableBackground("rgba(255, 0, 0, 0.2)");
      setTextcolor("red");
    }
    

  }

  function handleReload(){
    window.location.reload();
  }




  
  
  
  
  return (
    <Box sx={{ '@scale.go': '1',display: "flex", justifyContent: "center", height: "100vh", justifyContent:"space-around", backgroundColor: "#185519" }}>
      <Box sx={{ width:"30%", height: "90%", display: "flex", justifyContent: "center", marginTop: "20px"}}>
      <Paper sx={{ width: '90%', boxShadow: '0px 4px 10px rgba(0, 0, 0, 1)', height: "105%" }}>
  <TableContainer sx={{ maxHeight: "95%" }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} variant="h6">Number of Players</Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} variant="h6">{data.userCount}</Typography>
          </TableCell>
          <TableCell align="center" colSpan={3}>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} variant="h6">Total Amount</Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} variant="h6">LKR {data.totalBetAmount}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ top: 57, minWidth: column.minWidth, backgroundColor: "#00712D", color: "white", fontSize: "80%" }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {betData.map((bet, index) => {
          // Determine the background and text color dynamically
          const tableBackground = showWinVideo
          ? (bet.winning_player !== sideRef.current
            ? "rgba(255, 0, 0, 0.2)" // Red background for loss
            : "rgba(0, 255, 0, 0.2)") // Green background for win
          : "white"; // Default white background when showWinVideo is false
      
        const textcolor = showWinVideo
          ? (bet.winning_player === sideRef.current
            ? "green" // Green text color for win
            : "red") // Red text color for loss
          : "black"; // Default black text color when showWinVideo is false

          return (
            <TableRow key={index} sx={{ backgroundColor: tableBackground }}>
              <TableCell align='start' sx={{ color: textcolor }}>{bet.id}</TableCell>
              <TableCell align='center' sx={{ color: textcolor }}>{bet.bet_amount}</TableCell>
              <TableCell align='center' sx={{ color: textcolor }}>{bet.wining_profit}</TableCell>
              {/* Add more table columns based on your Bet_Detail data */}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  <TablePagination
    rowsPerPageOptions={[]}
    component="div"
    count={betData.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
</Paper>

      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#00712D", width: "40%", height: "100%" }}>
        <Box sx={{ display: "flex", width: "30%",  flexDirection: "column" }}>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <Typography sx={{ color: "white", fontSize: "20px", fontWeight: "bold", marginLeft: "10px", marginTop: "10%", textAlign: "center", position: "absolute" }}>Card Game</Typography>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "relative", marginTop: "20%" }}>
              {cards}
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "100% " }}>
            <Typography sx={{ color: "white", fontSize: "20px", fontWeight: "bold", marginLeft: "10px", marginTop: "10%", textAlign: "center" }}>Trial Cards</Typography>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "relative" }}>
              {/* Empty box for trial cards */}
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "120%" }}>
            <Typography sx={{ color: "white", fontSize: "20px", fontWeight: "bold", marginLeft: "10px", marginTop: "10px", textAlign: "center" }}>Game Card</Typography>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center", position: "relative" }}>
              <Avatar src={allCardImages[gameCardIndex]} sx={{ width: "60%",marginLeft:"5%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "20%" }} />
              {/* <Avatar src={allCardImages[gameCardIndex+13]} sx={{ width: "60%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "28%" }} />
              <Avatar src={allCardImages[gameCardIndex+26]} sx={{ width: "60%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "36%" }} />
              <Avatar src={allCardImages[gameCardIndex+39]} sx={{ width: "60%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "44%" }} /> */}
            </Box>
          </Box>
        </Box>
        <Box sx={{width: "70%",  height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", position: "relative" }}>
          <Typography sx={{ color: "white", fontSize: "20px", fontWeight: "bold", textAlign: "center", backgroundColor: "rgba(174,139,0, 0.5)", padding: "10px", borderRadius: "10px", width: "30%", position: "absolute", top: "2vh" }}>Player 2</Typography>
            <Box sx={{ height:"100vh", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
              <Avatar src={Board} sx={{ width: "auto",  height: "90%", borderRadius: "0px" }} />
            </Box>
          <Typography sx={{ color: "white", fontSize: "20px", fontWeight: "bold", textAlign: "center", backgroundColor: "rgba(174,139,0, 0.5)", padding: "10px", borderRadius: "10px", width: "30%", position: "absolute", top: "90vh" }}>Player 1</Typography>
        </Box>
      </Box>
      <Box sx={{ width:"30%", height: "93%", display: "flex", justifyContent: "center", marginTop:"20px", borderRadius:"0px"}}>
        
        <Box sx={{ width: "90%",  display: "flex", flexDirection: "column" }}>
        { <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center", height: "40%", backgroundColor:"white",  borderRadius:"5px", boxShadow:"0px 4px 10px rgba(0, 0, 0, 1)" }}>
              <Typography sx={{ color: "black", fontSize: "15px", fontWeight: "bold", textAlign: "center", padding: "10px", borderRadius: "10px" }}>Winning Player</Typography>
              <Box sx={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                  <Button onClick={() => handleToggle("Player 01")} sx={{ fontSize:"12px", color: activePlayer === "Player 01" ? "white" : "black",  textAlign: "start", padding: "5px 10px", borderRadius: "10px", backgroundColor: activePlayer === "Player 01" ? "rgba(0,113,45, 1)" : "rgba(0,113,45, 0.5)", textTransform: "none", borderRadius:"none", width:"40%" }}>Player 01</Button>
                  <Button onClick={() => handleToggle("Player 02")} sx={{ fontSize:"12px", color: activePlayer === "Player 02" ? "white" : "black",  textAlign: "start", padding: "5px 10px", borderRadius: "10px", backgroundColor: activePlayer === "Player 02" ? "rgba(0,113,45, 1)" : "rgba(0,113,45, 0.5)", textTransform: "none",  borderRadius:"none", width:"40%" }}>Player 02</Button>
              </Box>
              
              <input
        type="number"
        placeholder="Betting Amount"
        value={betAmount} // Bind input field to state
        onChange={(e) => setBetAmount(e.target.value)} // Update state when input changes
        style={{
          margin: "10px 0px",
          width: "85%",
          border: "1px solid #00712D",
          borderRadius: "5px",
          padding: "5px",
          outline: "none",
        }}
      />
      <Box sx={{ width: "90%", marginTop: "-5px" }}>
        <Grid container spacing={0.2}>
          {["100", "200", "300", "400", "500", "600"].map((value, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: selectedValue === value ? "#00712D" : "rgba(0,113,45, 0.5)", // Change color based on selection
                  textTransform: "none",
                  borderRadius: "none",
                  fontSize: "10px",
                  height: "20px",
                }}
                onClick={() => handleToggle1(value)} // Update input value on click
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
        <Button  onClick={handlePlaceBet} variant="contained" fullWidth sx={{  backgroundColor: isBetPlaced ? "#808080" : "#00712D", textTransform: "none", borderRadius: "none", marginTop: "10px", width: "90%", fontSize: "12px", height: "30px" }} disabled={isBetPlaced || !bettingTime}>{isBetPlaced ? "Placed" : "Place Bet"}</Button>
      </Box>}
          <Paper sx={{ width: '100%', height: '60%', marginTop:"10px", boxShadow:"0px 4px 10px rgba(0, 0, 0, 1)" }}>
           <TableContainer sx={{ maxHeight: "90%" }}>
  <Table stickyHeader aria-label="sticky table">
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth, backgroundColor: "#00712D", color: "white" }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows1.length > 0 ? (
        rows1
          .slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
          .map((row) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell key={column.id} style={{ fontSize: "60%" }} align={column.align}>
                    {column.format && typeof value === 'number' ? column.format(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
      ) : (
        <TableRow>
          <TableCell colSpan={columns.length} align="center" style={{ fontSize: "2rem", height: "35vh" }}>
            <Typography variant="h6" sx={{ opacity: "0.5", fontSize: "12px" }}>You have no records</Typography>
              <svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px" fill="#e8eaed"><path d="M200-800v241-1 400-640 200-200Zm80 400h140q9-23 22-43t30-37H280v80Zm0 160h127q-5-20-6.5-40t.5-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v100q-19-8-39-12.5t-41-6.5v-41H480v-200H200v640h241q16 24 36 44.5T521-80H200Zm460-120q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM864-40 756-148q-21 14-45.5 21t-50.5 7q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7 50.5T812-204L920-96l-56 56Z"/></svg>
            </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>

            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={rows1.length}
              rowsPerPage={rowsPerPage1}
              page={page1}
              onPageChange={handleChangePage1}
              onRowsPerPageChange={handleChangeRowsPerPage1}
            />
          </Paper>
          
        
        </Box>
      </Box>

      {/* Shuffling Video */}
      {showShufflingVideo && (
          <Box
          sx={{
            position: "fixed",
            
            width: "40%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            backgroundColor: "rgba(0, 113, 45, 1)",
          }}
        >
          <img
            src={Shaffling}
            autoPlay
            muted
            onLoad={() => {
              // Set a timeout based on the GIF duration (in milliseconds)
              setTimeout(() => {
                // Shuffle the entire deck
                // let shuffled = shuffleArray([...allCardImages]);
                // setShuffledImages(shuffled);
            
                setShowShufflingVideo(false);
            
                // Add a delay before showing the countdown video
                setTimeout(() => {
                  setShowCountdownVideo(true);
                }, 5000); // 5-second delay before showing the countdown video
              }, 5000); // Replace 5000 with the actual GIF duration
            }}
            style={{ width: "100%", height: "auto" }}
            
          />

        </Box>

      )}

      {/* Countdown Video */}
      {showCountdownVideo && (
  <Box
  sx={{
    position: "fixed",
    width: "40%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "rgba(0, 113, 45, 1)",
  }}
>
  <video
    src={Win}
    autoPlay
    muted
    onPlay={() => {
      setBettingTime(true); // Set to true when the video starts
    }}
    onEnded={async () => {
      let newYouAmount = 0; 
      let newOpponentAmount = 1;
    
      try {
        const response = await axios.get('https://cardgamebackend.gamingesupport.xyz/api/player-total-bet'); // Replace with your actual backend URL
    
        newYouAmount = response.data.Player_One; // Now these can be reassigned
        newOpponentAmount = response.data.Player_Two;
    
        // Set state
        setYouAmount(newYouAmount);
        setOpponentAmount(newOpponentAmount);
    
        // Log the updated values from the API directly
        console.log('Fetched youAmount:', newYouAmount);
        console.log('Fetched opponentAmount:', newOpponentAmount);
      } catch (error) {
        console.error('Error fetching player bets:', error);
      }
    
      const newArray = [...allCardImages];
    
      player1Array.push(newArray[gameCardIndex]);
      player1Array.push(newArray[gameCardIndex + 13]);
      player1Array.push(newArray[gameCardIndex + 26]);
      player1Array.push(newArray[gameCardIndex + 39]);
    
      newArray.splice(gameCardIndex, 1);
      newArray.splice(gameCardIndex + 12, 1);
      newArray.splice(gameCardIndex + 24, 1);
      newArray.splice(gameCardIndex + 36, 1);
    
      for (let i = 0; i < 22; i++) {
        player1Array.push(newArray[0]);
        newArray.splice(0, 1);
      }
    
      let shuffledPlayer1Array = shuffleArray([...player1Array]);
      let shuffledNewArray = shuffleArray([...newArray]);
    
      const totalElements = shuffledNewArray.length + shuffledPlayer1Array.length;
    
      if (newYouAmount > newOpponentAmount) {
        for (let i = 0; i < totalElements; i++) {
          if (i % 2 === 0) {
            if (shuffledNewArray.length > 0) {
              shuffledImages.push(shuffledNewArray.shift());
            }
          } else {
            if (shuffledPlayer1Array.length > 0) {
              shuffledImages.push(shuffledPlayer1Array.shift());
            }
          }
        }
      } else {
        for (let i = 0; i < totalElements; i++) {
          if (i % 2 !== 0) {
            if (shuffledNewArray.length > 0) {
              shuffledImages.push(shuffledNewArray.shift());
            }
          } else {
            if (shuffledPlayer1Array.length > 0) {
              shuffledImages.push(shuffledPlayer1Array.shift());
            }
          }
        }
      }
    
      setBettingTime(false);
      setShowCountdownVideo(false);
      setCardsReadyToMove(true);
    }}
    
    style={{ width: "100%", height: "100%" }}
  />
</Box>
)}

{/* Win or Loss Video */}


{showWinVideo && (
  <Box
    sx={{
      position: "fixed",
      width: "40%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      backgroundColor: "rgba(0, 113, 45, 1)",
    }}
  >
    {activePlayer !== null ? (
      <video
        src={activePlayer === sideRef.current ? Win : Loss}
        autoPlay
        muted
        onPlay={() => {
          setCardsReadyToMove(true);
          updateWinner(sideRef.current);
          colorChange();
        }}
        onEnded={() => {
          setShowWinVideo(false);
          setActivePlayer('');
          setBettingTime(false);

          // Call handleReload here to refresh the page
          handleReload();
        }}
        style={{ width: "100%", height: "100%" }}
      />
    ) : (
      <Box fontSize={"50px"} sx={{ color: "white" }}>
        {handleReload()} {/* Calling handleReload directly */}
        Next Round
      </Box>
    )}
  </Box>
)}



     
    </Box>
  );
}

export default App;